
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'
@Component({
  name: 'RightItemView2'
})
export default class RightItemView1 extends Vue {
  @Prop() private projectId!: number
  @Prop() private time!: any
  @Prop() private active!: number
  list = [{
    name: '签到人数',
    value: 0
  },
  {
    name: '实名人数',
    value: 0
  },
  {
    name: '班组人数',
    value: 0
  }]

  _chart: any = null

  search = {
    projectId: '',
    startTime: '',
    endTime: ''
  }

  tabs = ['实名签到', '班组签到']
  current = 0
  tableList = []
  tableList1 = []

  @Watch('projectId')
  changeProjectId (val: string) {
    this.search.projectId = val
    if (this.search.projectId) {
      this._chart && this._chart.dispose()
    }
    if (this.search.startTime) {
      this.loadData()
    }
  }

  @Watch('time', { deep: true })
  changeTime (val: any) {
    this.search.startTime = val.startTime
    this.search.endTime = val.endTime
    this.loadData()
  }

  get onlyProject () {
    return this.search.projectId || ''
  }

  created () {
    // this.loadData()
  }

  destroyed () {
    this._chart && this._chart.dispose()
  }

  loadData () {
    this.$axios.get(this.onlyProject ? this.$apis.apicture.selectEmploymentCheckProject : this.$apis.apicture.selectEmployeeCheckAll, {
      ...this.search
    }).then(res => {
      this.list[0].value = res.signNum || 0
      this.list[1].value = res.workerNum || 0
      this.list[2].value = res.teamNum || 0
      if (this.onlyProject) {
        this.tableList = res.workerList || []
        this.tableList1 = res.teamList || []
        this.$event.$emit('signCompleted', res)
      } else {
        this.$nextTick(() => {
          this.renderChart(res.projectNameList, [res.workerNumList, res.teamNumList, res.projectIdList], ['实名人数', '班组人数'])
        })
      }
    })
  }

  goPosition (item: any) {
    // 1
    this.$event.$emit('signClick', item)
  }

  renderChart (x: any, y: any, legend: any) {
    this._chart = echarts.init(this.$refs.chart1 as any)
    const option = {
      grid: {
        top: 28,
        bottom: 35,
        left: 30,
        right: 0
      },
      legend: {
        top: 0,
        width: '100%',
        right: 'center',
        textStyle: {
          fontSize: 12,
          color: '#909090'
        },
        itemGap: 16,
        itemWidth: 14,
        itemHeight: 5
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        className: 'echarts-tooltip-dark',
        formatter (res: any) {
          const colors = ['#fff']
          let content = `${res[0].name}<br>`
          for (let i = 0; i < res.length; i++) {
            content += `${res[i].marker.replace('background-color:[object Object]', 'background-color:' + colors[i / 2])}${res[i].seriesName}  <span style="color:#fff};">${res[i].value}</span><br>`
          }
          return content
        }
      },
      xAxis: {
        show: true,
        data: x,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false // 隐藏X轴刻度
        },
        axisLabel: {
          show: true,
          fontSize: 10,
          fontWeight: 400,
          textStyle: {
            color: '#4A4A4A' // X轴文字颜色
          },
          formatter: (item: any) => {
            return item.length > 9 ? item.substring(0, 4) + '...' + item.substring(item.length - 4, item.length) : item
          }
        }
      },
      yAxis: [
        {
          name: '人',
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            margin: 4,
            fontSize: 12,
            textStyle: {
              color: '#4A4A4A' // X轴文字颜色
            }
          }
        }
      ],
      dataZoom: [{
        type: 'slider',
        show: x.length > 2,
        bottom: 0,
        height: 5,
        start: 0,
        end: 50, // 初始化滚动条
        borderColor: 'transparent',
        backgroundColor: '#D9D9D9',
        fillerColor: '#999999',
        handleStyle: {
          opacity: 0
        },
        showDetail: false,
        showDataShadow: false,
        brushSelect: false
      }],
      series: [
        {
          name: legend[0],
          data: y[0],
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#0361FE'
            },
            {
              offset: 1,
              color: '#ffffff'
            }
            ])
          },
          barWidth: 10,
          z: 0,
          zlevel: 1
        },
        {
          // 分隔
          name: legend[1],
          data: y[1],
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#3BE011'
            },
            {
              offset: 1,
              color: '#ffffff'
            }
            ])
          },
          barWidth: 10,
          z: 0,
          zlevel: 2
        }
      ]
    }
    this._chart.clear()
    this._chart.setOption(option)
    this._chart.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY]
      if (this._chart.containPixel('grid', pointInPixel)) {
        const xIndex = this._chart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)[0]
        this.$event.$emit('projectClick', y[2][xIndex])
      }
    })
  }
}

