<template>
  <el-card>
    <div class="basic">
      <div class="content">
        <div class="item">
          <p class="ttl">问题上报人：</p>
          <p class="info">{{info.createUserName || ''}}</p>
        </div>
        <div class="item">
          <p class="ttl">上报时间：</p>
          <p class="info">{{info.ctime || ''}}</p>
        </div>
        <div class="item w100">
          <p class="ttl">问题描述：</p>
          <p class="info">{{info.issueContent || ''}}</p>
        </div>
        <div class="item w100 item1">
          <p class="ttl">现场图片/视频：</p>
          <custom-upload v-if="info.fileList" v-model="info.fileList" disabled></custom-upload>
        </div>
        <div class="item w100 item1">
          <p class="ttl">整改图片/视频：</p>
          <custom-upload v-if="handleList" v-model="feekbackfileList" disabled></custom-upload>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'CustomDetail',
  props: {
    issueId: String,
    // 判断是否来自于问题详情，问题详情需要展示问题定位弹框，需要按钮操作，其他不需要
    isFromIssue: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  computed: {
    currentUserId () {
      let userId = ''
      if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
        userId = this.$store.state.userInfo.userId
      } else if (window.sessionStorage.getItem('userInfo')) {
        userId = JSON.parse(window.sessionStorage.getItem('userInfo')).userId
      }
      return userId
    }
  },
  watch: {
    issueId (val) {
      if (val) {
        this.info = {}
        this.handleList = []
        this.feekbackfileList = []
        this.loadData()
      }
    }
  },
  data () {
    return {
      activeNames: ['1', '2', '3'],
      handleTypeList: [{
        icon: '#iconicon_zhuanban',
        class: 'transfer',
        status: '转办'
      }, {
        icon: '#iconicon_banjie',
        class: 'complete',
        status: '办结'
      }, {
        icon: '#iconicon_fankui',
        class: 'feedback',
        status: '反馈'
      }, {
        icon: '#iconicon_shenhe',
        class: 'audit',
        status: '审核'
      }, {
        icon: '#iconicon_guanbi',
        class: 'close',
        status: '关闭'
      }],
      handleList: [],
      info: {
        fileList: []
      },
      issueInfo: {},
      closeShow: false,
      transferShow: false,
      feedbackShow: false,
      auditShow: false,
      transInfo: {
        projectId: '',
        safeManageId: '',
        currentBusinessType: '',
        currentHandleUserId: ''
      },
      locationShow: false,
      feekbackfileList: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.patrol.selectIssueByIssueId, {
        issueId: this.issueId
      }).then(res => {
        this.info = res || {}
        this.handleList = res.handleList || []
        this.handleList.forEach((item) => {
          if (item.fileList) {
            this.feekbackfileList = this.feekbackfileList.concat(item.fileList)
            console.log(this.feekbackfileList)
          }
        })
      })
    },
    onTransfer () {
      this.transInfo = {
        projectId: this.info.projectId,
        currentSafeManageId: this.info.currentSafeManageId,
        currentHandleUserId: this.info.currentHandleUserId
      }
      this.transferShow = true
    },
    onFeedback () {
      this.issueInfo = {
        issueId: this.issueId,
        projectId: this.info.projectId,
        currentSafeManageId: this.info.currentSafeManageId
      }
      this.feedbackShow = true
    },
    computeBusinessName (val) {
      let name = ''
      if (val) {
        const ind = this.typeList.findIndex(item => item.typeId === val)
        name = ind >= 0 ? this.typeList[ind].fullTitle : ''
      }
      return name
    }
  }
}
</script>

<style scoped lang="scss">
  .cursor {
    cursor: pointer;
  }
  .basic {
    position: relative;
    .buttonBlock {
      position: absolute;
      top: 0px;
      right: -20px;
      span {
        display: inline-block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        padding: 5px 16px;
        border-radius: 16px 0 0 16px;
      }
    }
  }
  .title{
    text-align: left;
    margin-bottom: 25px;
    font-size: 14px;
  }
  .line {
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #EAEEF5;
  }
  .content, .timeline .item-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    &.item-content {
      width: 100%;
    }
    .item {
      display: flex;
      width: 320px;
      font-size: 14px;
      line-height: 22px;
      margin:0 20px 10px 0;
      .ttl {
        width: auto;
        color: #000;
        white-space: nowrap;
      }
      .info {
        color: #666;
      }
      &.w100 {
        width: 100%;
      }
    }

    .item1{
      margin:0 20px -10px 0 !important;
    }
  }
</style>
