
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CustomDialog from './CustomDialog.vue'
import RightItemView1 from './RightItemView1.vue'
import RightItemView2 from './RightItemView2.vue'
import RightItemView3 from './RightItemView3.vue'
@Component({
  components: { CustomDialog, RightItemView1, RightItemView2, RightItemView3 }
})
export default class RightItem extends Vue {
  @Prop() private projectId!: string
  time0 = null
  time1 = null
  time2 = null
  active = 0
  created () {
    // 1
  }

  @Watch('time0', { deep: true })
  listenDefaultDate (val: any) {
    this.$event.$emit('patrolTimeChange', val)
  }

  setActive (val: number) {
    this.active = val
  }
}

