
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import ImageViewer from '@/components/ImageViewer.vue'
import OrderDetailDialog from '@/views/workOrder/OrderDetailDialog.vue'
import CustomDetail from '@/views/patrol/issue/CustomDetail.vue'
import TaskDetail from '@/views/patrol/task/TaskDetail.vue'
import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'
@Component({
  components: { ImageViewer, OrderDetailDialog, CustomDetail, TaskDetail, Waterfall, LazyImg },
  filters: {
    imgListFormat (list: FileInfo[]) {
      return list || []
    }
  }
})
export default class Cloud extends Vue {
  @Prop() private projectId!: string
  showDialog = false
  dialogVisible = false
  current = 5
  tabs = [{
    name: '作业日报',
    value: 5
  },
  {
    name: '品质巡查',
    value: 1
  },
  {
    name: '巡查问题',
    value: 2
  },
  {
    name: '任务工单',
    value: 3
  },
  {
    name: '用工签到',
    value: 4
  }]

  photoList = []
  type = 0
  date = this.$dayjs().format('YYYY-MM-DD')
  currentImgIndex = 0

  files: any = []

  currentIndex = 0
  currentOrderId = ''
  orderVisible = false
  patrolVisible = false
  currentIssueId = ''
  taskVisible = false
  currentTaskId = ''
  loading = false

  get formatFileList () {
    return this.files.map((item: any) => {
      return { url: (item.imgUrl || item), name: item.fileName, extra: item.issueContent || item.orderContent || item.facilityName || item.taskNotes }
    })
  }

  search = {
    type: 5,
    projectId: this.projectId,
    startTime: '',
    endTime: ''
  }

  videoType = '.mp4,.mov'
  timeList = []
  currentTime = ''

  @Watch('showDialog')
  listenShowDialog (val: string) {
    if (!val) {
      this.type = 0
      this.current = 5
    }
    val && this.getTimeLine()
  }

  @Watch('projectId')
  listenProjectId (val: string) {
    this.search.projectId = val
  }

  @Watch('current')
  changeIndex (val: number) {
    this.search.type = val
    this.photoList = []
    this.search.startTime = ''
    this.search.endTime = ''
    this.getTimeLine()
    ;(this.$refs.timeline as any).scrollTo({ top: 0 })
  }

  hideImageView () {
    this.files = []
  }

  previewImage (files: any, index: number) {
    this.files = files
    this.currentIndex = index
    this.dialogVisible = true
  }

  showDetail (data: any) {
    // 作业工单
    if (this.current === 2) {
      this.currentOrderId = data.orderId
      this.orderVisible = true
    }
    if (this.current === 1) {
      this.currentIssueId = data.issueId
      this.patrolVisible = true
    }
    if (this.current === 0) {
      this.currentTaskId = data.taskId
      this.taskVisible = true
    }
  }

  getTimeLine () {
    this.$axios.get(this.$apis.apicture.selectPictureWaterfallFlowDate, {
      ...this.search
    }).then(res => {
      this.timeList = res || []
      this.currentTime = this.timeList[0]
      this.photoList = []
      this.search.startTime = this.currentTime
      this.search.endTime = this.currentTime
      this.loadData()
    })
  }

  loadPhoto (item: any) {
    this.currentTime = item
    this.search.startTime = item
    this.search.endTime = item
    this.photoList = []
    this.loadData()
  }

  setPhoto (val: number) {
    if (!(this.currentImgIndex === 0 && val < 0) && !(this.currentImgIndex === this.photoList.length - 1 && val > 0)) {
      this.currentImgIndex += val
      ;(this.$refs.scroll as any).scrollLeft = 110 * this.currentImgIndex
    }
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.apicture.selectPictureWaterfallFlow, {
      ...this.search
    }).then(res => {
      this.currentImgIndex = 0
      if (this.current === 4) {
        this.photoList = res.list.map((item: any) => {
          return { imgUrl: item }
        })
      } else {
        this.photoList = res.list || []
      }
      this.loading = false
    })
  }
}
