
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'
@Component
export default class LeftItem extends Vue {
  @Prop() private projectId!: string
  current = 0
  fold = true
  deviceType = 'all'
  divStyle = {
    transform: 'rotate(360deg)'
  }

  _chart: any = null
  _chart1: any = null
  renderList: any = {}
  sum = 0

  list = [{
    name: '全部设备',
    val: '',
    icon: 'qbsb',
    active: true,
    param: 'all'
  },
  {
    name: '土壤监测',
    val: '',
    icon: 'trjc',
    active: false,
    param: 'soil'
  },
  {
    name: '水质监测',
    val: '',
    icon: 'shzc',
    active: false,
    param: 'water'
  },
  {
    name: '环境监测',
    val: '',
    icon: 'hjjc',
    active: false,
    param: 'weather'
  },
  {
    name: '智慧虫情',
    val: '',
    icon: 'zhcq',
    active: false,
    param: 'insect'
  },
  {
    name: '智慧监控',
    val: '',
    icon: 'jk',
    active: false,
    param: 'camera'
  },
  {
    name: '割草机器人',
    val: '',
    icon: 'cg',
    active: false,
    param: 'mowing'
  },
  {
    name: '智慧喷灌',
    val: '',
    icon: 'pg',
    active: false,
    param: 'irrigation'
  }]

  alarm = [{
    name: '正常',
    value: 0,
    color: '#628DF6'
  }, {
    name: '告警',
    value: 0,
    color: '#E22828'
  }]

  get onlyProject () {
    return this.projectId || ''
  }

  get getStyle () {
    return !this.fold ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  get isIrrigation () {
    return this.deviceType === 'irrigation'
  }

  get isHasAlarm () {
    if (this.renderList[this.deviceType]) {
      return this.renderList[this.deviceType].runOnDeviceNum || +this.renderList[this.deviceType].runOnDeviceNum === 0
    }
    return false
  }

  @Watch('projectId')
  changeProjectId (val: string) {
    if (val) {
      this._chart1 && this._chart1.dispose()
    }
    this.loadData()
  }

  created () {
    this.loadData()
  }

  destroyed () {
    this._chart && this._chart.dispose()
    this._chart1 && this._chart1.dispose()
  }

  getIcon (item: any) {
    return require('../../assets/img/apicture/' + (item.active ? item.icon + '_check.png' : item.icon + '_uncheck.png'))
  }

  setCurrent (index: number) {
    if (this.current === index) {
      this.list[this.current].active = !this.list[this.current].active
      this.fold = !this.fold
    } else {
      this.list[this.current].active = false
      this.current = index
      this.list[index].active = true
      this.fold = true
    }
    this.deviceType = this.list[index].param
    const list = this.renderList[this.deviceType]
    this.refreshEchart(list)
  }

  setDisplay () {
    this.fold = !this.fold
    this.$forceUpdate()
  }

  loadData () {
    this.$axios.get(this.onlyProject ? this.$apis.apicture.selectEquipmentListProject : this.$apis.apicture.selectEquipmentStatisticsAll, {
      projectId: this.projectId
    }).then(res => {
      this.renderList = res
      this.refreshEchart(res[this.deviceType])
    })
  }

  // 重新绘制
  refreshEchart (res: any) {
    this._chart && this._chart.clear()
    this._chart1 && this._chart1.clear()
    if (res.offDeviceNumList) {
      this.sum = res.offDeviceNumList.reduce((prev: any, cur: any, index: any) => {
        return prev + cur
      })
    } else {
      this.sum = 0
    }
    this.$nextTick(() => {
      this.renderPie(res)
      if (!this.onlyProject && !this.isIrrigation) {
        this.renderChart1(res.projectNameList, this.isHasAlarm ? [res.offDeviceNumList, res.runOffDeviceNumList] : [res.offDeviceNumList], this.isHasAlarm ? ['离线', '告警'] : ['离线'])
      }
    })
    if (this.isHasAlarm) {
      this.alarm[0].value = res.runOnDeviceNum
      this.alarm[1].value = res.runOffDeviceNum
    }
  }

  renderPie (res: any) {
    const color = ['#628DF6', '#89F3F7', '#FEE186']
    let data: any = []
    let title: any = {}
    if (this.isIrrigation) {
      data = [{
        name: '总数',
        value: res.deviceNum || 0
      },
      {
        name: '开启',
        value: res.onSwitchNum || 0
      },
      {
        name: '关闭',
        value: res.offSwitchNum || 0
      }]
    } else {
      data = [{
        name: '总数',
        value: res.deviceNum || 0
      },
      {
        name: '在线',
        value: res.onDeviceNum || 0
      },
      {
        name: '离线',
        value: res.offDeviceNum || 0
      }]
      title = {
        name: '在线率',
        per: (+res.deviceNum === 0 || !res.deviceNum) ? 0 : (+res.onDeviceNum / +res.deviceNum * 100).toFixed(2)
      }
      console.log(title)
    }
    setTimeout(() => {
      this.renderChart(data, 'chart', color, title)
    }, 500)
  }

  renderChart (data: any, chart: any, color: any, title?: any) {
    this._chart = echarts.init(this.$refs[chart] as any)
    const option = {
      tooltip: {
        show: false,
        formatter: '{a} <br/>{b}: {d}%',
        textStyle: {
          fontSize: 12
        }
      },
      title: {
        show: title.name,
        text: title.per + '{per|%}',
        subtext: title.name,
        left: '18%',
        textAlign: 'center',
        top: 20,
        itemGap: 3,
        textStyle: {
          fontSize: 20,
          color: '#9013FE',
          fontFamily: 'DINCondensed, DINCondensed',
          rich: {
            per: {
              fontSize: 12,
              fontFamily: 'DINCondensed, DINCondensed'
            }
          }
        },
        subtextStyle: {
          fontSize: 8,
          color: '#000000',
          fontFamily: 'PingFangSC, PingFang SC'
        }
      },
      legend: {
        orient: 'vertical',
        top: 'center',
        left: '51%',
        icon: 'circle',
        itemWidth: 6,
        formatter: (name: string) => {
          const index = data.findIndex((item: any) => item.name === name)
          const arr = '{a|' + name + '}{b|' + data[index].value + '}'
          return arr
        },
        textStyle: {
          fontSize: 12,
          color: '#4A4A4A',
          align: 'center',
          rich: {
            a: {
              color: '#4A4A4A',
              padding: [0, 0, 0, 2]
            },
            b: {
              color: '#4A4A4A',
              padding: [15, 0, 15, 28]
            }
          }
        }
      },
      color: color,
      series: [
        {
          // avoidLabelOverlap: false,
          hoverOffset: 0,
          type: 'pie',
          radius: ['90%', '100%'],
          center: ['20%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          zlevel: 4,
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 0,
              color: (value: any) => {
                return color[value.dataIndex]
              }
              // shadowColor: 'rgba(0,0,0,0.4)',
              // shadowBlur: 10
            }
          },
          data: data,
          labelLine: {
            show: false
          }
        },
        {
          labelLine: {
            show: false
          },
          itemStyle: {
            opacity: 0.2,
            borderColor: '#ffffff',
            borderWidth: 2
          },
          type: 'pie',
          hoverAnimation: false,
          radius: ['70%', '90%'],
          center: ['20%', '50%'],
          label: {
            normal: {
              show: false
            }
          },
          data: data,
          zlevel: -1
        }
      ]
    }
    this._chart.clear()
    this._chart.setOption(option)
  }

  renderChart1 (x: any, y: any, legend: any) {
    this._chart1 = echarts.init(this.$refs.chart1 as any)
    const option = {
      grid: {
        top: 0,
        bottom: 40,
        left: 70,
        right: 0
      },
      color: ['#919191', '#E22828'],
      legend: {
        bottom: 0,
        left: 0,
        width: '100%',
        right: 'center',
        textStyle: {
          fontSize: 12,
          color: '#909090'
        },
        itemGap: 16,
        itemWidth: 14,
        itemHeight: 5
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        className: 'echarts-tooltip-dark',
        formatter (res: any) {
          const colors = ['#fff']
          let content = `${res[0].name}<br>`
          for (let i = 0; i < res.length; i++) {
            content += `${res[i].marker.replace('background-color:[object Object]', 'background-color:' + colors[i / 2])}${res[i].seriesName}  <span style="color:#fff};">${res[i].value}</span><br>`
          }
          return content
        }
      },
      xAxis: {
        type: 'value',
        show: true,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false // 隐藏X轴刻度
        },
        splitLine: {
          show: false // 隐藏X轴刻度
        },
        axisLabel: {
          show: false,
          fontSize: 10,
          fontWeight: 400,
          textStyle: {
            color: '#4A4A4A' // X轴文字颜色
          }
        }
      },
      yAxis: [
        {
          type: 'category',
          data: x,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            margin: 4,
            fontSize: 12,
            formatter: (value: any) => {
              if (value.length > 4) {
                return value.substring(0, 4) + '...   '
              }
              return value
            },
            textStyle: {
              color: '#4A4A4A' // X轴文字颜色
            }
          }
        }
      ],
      series: [
        {
          name: legend[0],
          data: y[0],
          type: 'bar',
          barWidth: 10,
          z: 0,
          zlevel: 1
        }
      ]
    }
    if (y[1]) {
      option.series.push({
        // 分隔
        name: legend[1],
        data: y[1],
        type: 'bar',
        barWidth: 10,
        z: 0,
        zlevel: 2
      })
    }
    this._chart1.clear()
    this._chart1.setOption(option)
  }
}

