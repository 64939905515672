<template>
  <div class="fullScreen" @click="fullScreenClick">
    <img :class="{ active: fullScreen }" :type="fullScreen? 'primary' : ''" src="../../assets/img/apicture/fullscreen.png">
    <span>{{ fullScreen ? '退出全屏' : '全屏'}}</span>
  </div>
</template>

<script>
// @Prop() private fullElementId!: string
export default {
  data () {
    return {
      fullScreen: false
    }
  },
  mounted () {
    window.addEventListener('resize', this.changeResize, false)
  },
  destoryed () {
    window.removeEventListener('resize', this.changeResize)
  },
  methods: {
    changeResize () {
      // this.changeResize()
      if (this.timer) return
      this.timer = setTimeout(() => {
        // 如果是按 "Esc" 键退出全屏，窗口状态已经是非全屏，但是之前记录的状态还是全屏的状态
        if (!this.checkFull()) {
          // 退出全屏状态。。。
          console.log('按 "Esc" 键退出全屏')
          this.fullScreen = false
        } else {
          // 进入全屏状态。。。
          console.log('进入全屏状态')
          this.fullScreen = true
        }
        this.$event.$emit('fullscreenStyleChange', this.fullScreen)
        clearTimeout(this.timer)
        this.timer = null
      }, 0)
    },
    // 全屏按钮\退出全屏按钮点击事件
    fullScreenClick () {
      this.fullScreen = !this.fullScreen
      this.$event.$emit('fullscreenStyleChange', this.fullScreen)
      setTimeout(() => {
        if (this.fullScreen) {
          // 当前要启动全屏
          this.setWindowFullScreen()
        } else {
          // 当前要退出全屏
          this.windowExitFullScreen()
        }
      }, 50)
    },
    // 启动全屏
    setWindowFullScreen () {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      }
    },
    // 退出全屏
    windowExitFullScreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },
    // 判断浏览器是否处于全屏状态 (需要考虑兼容问题)
    checkFull () {
      // 火狐浏览器
      let isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) isFull = false
      return isFull
    }
  }
}
</script>

<style scoped lang="scss">
.fullScreen{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 10px;
  cursor: pointer;
  img{
    width: 100px;
    height: 100px;
  }
  span{
    position: absolute;
    bottom: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 12px;
    color: #24278C;
    text-align: right;
    font-style: normal;
  }
}
</style>
