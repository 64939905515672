<template>
  <div class="apicture-content" id="apicture-content">
    <div id="map2" ref="map"></div>
    <div class="tool tool1" @click="toolPanelVisible = !toolPanelVisible">
      <img src="@/assets/img/apicture/icon1.png" />
      <span>地图图层</span>
    </div>

    <div class="tool1-panel" v-if="toolPanelVisible">
      <div class="tabs-header">
        <div class="tab-item" :class="{ active: toolPanelTab === 1 }" @click="toolPanelTab = 1">
          <span>图层管理</span>
        </div>
        <div class="tab-item" :class="{ active: toolPanelTab === 2 }" @click="toolPanelTab = 2">
          <span>地图模式</span>
        </div>
      </div>
      <div class="tab-content">
        <template v-if="toolPanelTab === 1">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
            @change="handleCheckAllChange">全部显示</el-checkbox>
          <el-divider></el-divider>
          <el-checkbox-group v-model="settingOptions" @change="handleCheckChange">
            <div class="item-label">巡查任务</div>
            <el-checkbox label="line">线路巡查</el-checkbox>
            <el-checkbox label="point">点位巡查</el-checkbox>
            <el-divider></el-divider>
            <el-checkbox label="XC-2">已完成</el-checkbox>
            <el-checkbox label="XC-1">执行中</el-checkbox>
            <el-checkbox label="XC-0">未执行</el-checkbox>
            <el-divider></el-divider>
            <div class="item-label">巡查问题</div>
            <el-checkbox label="WT-Y">已处理</el-checkbox>
            <el-checkbox label="WT-N">未处理</el-checkbox>
            <el-divider></el-divider>
            <div class="item-label">作业工单</div>
            <el-checkbox label="GD-N">未完成</el-checkbox>
            <el-checkbox label="GD-Y">已完成</el-checkbox>
            <el-divider></el-divider>
            <div class="item-label">其他</div>
            <el-checkbox label="sign">考勤签到</el-checkbox>
            <el-checkbox label="grid">网络区域</el-checkbox>
          </el-checkbox-group>
        </template>
        <template v-if="toolPanelTab === 2">
          <el-checkbox-group v-model="layerOptions" @change="layerOptionsChange">
            <el-checkbox label="0">2D地图</el-checkbox>
            <el-checkbox label="1">卫星影像</el-checkbox>
          </el-checkbox-group>
        </template>
      </div>
    </div>

    <!-- <div class="tool tool2">
      <img src="@/assets/img/apicture/icon3.png" />
      <span>云游览</span>
    </div>

    <div class="tool tool3" @click="fullScreenClick">
      <img src="@/assets/img/apicture/icon2.png" />
      <span>全屏</span>
    </div> -->

    <div class="project-content">
      <div class="prev" @click="prevClick"></div>
      <div class="items" v-if="projectList.length > 1">
        <div class="item" id="all" @click="projectClick(-1)" :class="{ active: currentIndex === -1 }">
          <span>全部项目</span>
        </div>
      </div>
      <div class="items itemslist">
        <div class="item" :id="item.projectId" @click="projectClick(index)" :class="{ active: index === currentIndex }"
          v-for="(item, index) of projectList" :key="index">
          <span>{{ item.projectName.length > 9 ? item.projectName.substring(0, 4) + '...' + item.projectName.substring(item.projectName.length - 4, item.projectName.length) : item.projectName }}</span>
        </div>
      </div>
      <div class="next" @click="nextClick"></div>
    </div>

    <div v-if="fullscreen" class="apicture-header">
      智护通管养一张图
    </div>
    <LeftItem :projectId="currentProjectId"></LeftItem>
    <RightItem :projectId="currentProjectId"></RightItem>
    <Fullscreen fullElementId="apicture-content"></Fullscreen>
    <Cloud :projectId="currentProjectId"></Cloud>

    <OrderDetailDialog  :class="{ center: orderStyle }" style="height: 380px; width: 500px;
    margin: auto;" v-if="orderVisible" ref="order" :detailShow="orderVisible" :isMyOrder="false" :orderId="currentOrderId"
      handleType="4" :show-location="false" @closeDialog="orderVisible = false"></OrderDetailDialog>
    <!-- <el-drawer ref="question" title="问题详情" :modal="false" :visible.sync="patrolVisible">
      <CustomDetail :show-location="false" :issueId="currentIssueId" :isFromIssue="false"></CustomDetail>
    </el-drawer> -->
    <CustomDetail style="height: 370px; width: 500px;" v-show="patrolVisible" ref="question" :show-location="false" :issueId="currentIssueId" :isFromIssue="false"></CustomDetail>
    <!-- <OrderDetailDialog v-if="orderVisible" :detailShow="orderVisible" :isMyOrder="false" :orderId="currentOrderId"
      handleType="4" @closeDialog="orderVisible = false"></OrderDetailDialog> -->
    <!-- 点位详情 -->
    <div class="moveWindow" ref="window" v-show="showPointWindow">
      <PointWindow :info="taskParams"></PointWindow>
    </div>

    <!-- <image-viewer :visible.sync="imageVisible" :url-list="imgFileList" :initial-index="currentImgIndex"></image-viewer> -->
    <div class="sing-info-tip" ref="sign">
      <el-image :src="currentSign.signImage" :preview-src-list="[currentSign.signImage]"></el-image>
      <div class="right">
        <span v-if="currentSign.signNum">班组：{{ currentSign.teamName }}</span>
        <span v-else>姓名：{{ currentSign.workerName }}</span>
        <span>签到：{{ currentSign.signType === '1' ? '上班' : '下班' }}</span>
        <span v-if="currentSign.signNum">人数：{{ currentSign.signNum || '--' }}</span>
        <span>时间：{{ currentSign.signTime || '--' }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import LeftItem from '@/views/apicture/LeftItem'
import RightItem from '@/views/apicture/RightItem'
import Fullscreen from '@/views/apicture/Fullscreen'
import Cloud from '@/views/apicture/Cloud'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey, iconUrl } from '@/utils/config'
import { getPolygons } from '@/utils/formatData'
import * as turf from '@turf/turf'
import * as helpers from '@turf/helpers'
import hongdian from '@/assets/img/apicture/hongdian.png'
import issueIconY from '@/assets/img/apicture/issue_finished.svg'
import issueIconN from '@/assets/img/apicture/issue.svg'
import qian1 from '@/assets/img/apicture/qian-1.svg'
import qian2 from '@/assets/img/apicture/qian-2.svg'
import qian4 from '@/assets/img/apicture/qian-4.svg'
import qiandao from '@/assets/img/apicture/kqqiandao.png'
import OrderDetailDialog from './OrderDetailDialog.vue'
import CustomDetail from './CustomDetail.vue'
import PointWindow from '@/views/apicture/PointWindow.vue'

// import ImageViewer from '@/components/ImageViewer.vue'
export default {
  name: 'APicture',
  components: { LeftItem, RightItem, Fullscreen, Cloud, CustomDetail, OrderDetailDialog, PointWindow },
  data () {
    return {
      toolPanelVisible: false,
      fullscreen: false,
      imageVisible: false,
      currentImgIndex: 0,
      toolPanelTab: 1,
      currentIndex: -1,
      checkAll: false,
      isIndeterminate: false,
      settingOptions: ['line', 'point', 'XC-2', 'XC-1', 'XC-0', 'WT-Y', 'WT-N', 'sign', 'grid', 'GD-N', 'GD-Y'],
      layerOptions: ['0'],
      currentProjectId: '',
      orderVisible: false,
      patrolVisible: false,
      currentOrderId: '',
      currentIssueId: '',
      showPointWindow: '',
      taskParams: {
        startTime: '',
        endTime: '',
        patrolMode: 1,
        facilityId: '',
        facilityName: '',
        planId: '',
        planIds: ''
      }, // 点位弹框
      imgFileList: [],
      currentSign: {},
      orderStyle: false
    }
  },
  computed: {
    projectList () {
      return this.$store.state.projectList
    },
    activeProjectId () {
      let projectId = 'all'
      if (this.currentIndex > -1) {
        projectId = this.projectList[this.currentIndex].projectId
      }
      return projectId
    }
  },
  watch: {
    currentProjectId (val) {
      if (val) {
        this._allProjectOverlayGroup.hide()
      } else {
        this._orderYGroup.clearOverlays()
        this._orderYGroup.clearOverlays()
        this._orderNGroup.clearOverlays()
        this._projectLineGroup.clearOverlays()
        this._projectPointGroup.clearOverlays()
        this._signGroup.clearOverlays()
        this._projectIssueYGroup.clearOverlays()
        this._projectIssueNGroup.clearOverlays()
        this._allProjectOverlayGroup.show()
        this._infoWindow && this._infoWindow.close()
      }
    },
    settingOptions (val, oldVal) {
      const arr1 = val.filter(item => !oldVal.includes(item))
      const arr2 = oldVal.filter(item => !val.includes(item))
      const option = [...arr1, ...arr2]
      localStorage.setItem('controlSetting', JSON.stringify(val))
      this.controlOverlay(option, option.some(item => val.includes(item)))
    },
    activeProjectId (val) {
      this.resetMap()
      if (this.currentProjectId && val !== 'all') {
        this.currentProjectId = val
      } else if (val === 'all') {
        this.currentProjectId = ''
        const id = this.projectList[0].projectId
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
      }
    },
    currentIndex (val) {
      // 有当前项目代表单个项目视角
      if (this._projectOverlayGroup) { // 切换项目时需要清除项目覆盖物
        this._map.remove(this._projectOverlayGroup)
        this._map.remove(this._projectGridGroup)
      }
      if (this.currentProjectId) {
        this.loadProjectFence()
      } else {
        const projectId = val === -1 ? 'all' : this.projectList[val].projectId
        const polygons = this._map.getAllOverlays('polygon')
        if (projectId === 'all') {
          this._map.setFitView(polygons, true)
        } else {
          const project = this._polygonsProject.find(item => item.projectId === projectId)
          if (project.locationList && project.locationList.length) {
            // const fenceCodes = [...new Set(project.locationList.map(item => item.fenceCode))]
            // const currentPolygon = polygons.filter(item => {
            //   return fenceCodes.includes(item.getExtData().fenceCode)
            // })
            // this._map.setFitView(currentPolygon, true)
            this._map.setCenter([project.longitude, project.latitude], true)
          }
        }
      }
    }
  },
  created () {
    // 根据默认配置去获取全部选择的加载状态
    const setting = JSON.parse(localStorage.getItem('controlSetting') || '[]')
    if (setting && setting.length) {
      this.settingOptions = setting
    }
    this.handleCheckChange(this.settingOptions)
    this.$event.$on('taskClick', this.taskClick)
    this.$event.$on('taskLoadCompleted', this.taskLoadCompleted)

    this.$event.$on('workOrderClick', this.workOrderClick)
    this.$event.$on('workOrderCompleted', this.workOrderCompleted)

    this.$event.$on('signClick', this.signClick)
    this.$event.$on('signCompleted', this.signCompleted)
    this.$event.$on('fullscreenStyleChange', (val) => {
      this.fullscreen = val
    })
    this.$event.$on('projectClick', (val) => {
      const index = this.projectList.findIndex(item => item.projectId === val)
      this.projectClick(index)
      this.currentProjectId = this.projectList[index].projectId
    })
    this.$event.$on('patrolTimeChange', (val) => {
      if (val) {
        this.taskParams.startTime = val.startTime
        this.taskParams.endTime = val.endTime
      }
    })
    this.$event.$on('drawPatrolLine', (val) => {
      this.drawLineOrPoints(val)
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.loadMap().then(() => {
        // 判断一个项目的情况
        if (this.projectList && this.projectList.length === 1) {
          this.currentIndex = 0
          this.currentProjectId = this.projectList[0].projectId
        } else {
          this.loadFence()
        }
      })
    })
  },
  beforeDestroy () {
    this.$event.$off('taskClick', this.taskClick)
    this.$event.$off('taskLoadCompleted', this.taskLoadCompleted)
    this.$event.$off('workOrderClick', this.workOrderClick)
    this.$event.$off('workOrderCompleted', this.workOrderCompleted)
    this.$event.$off('signClick', this.signClick)
    this.$event.$off('signCompleted', this.signCompleted)
    this.$event.$off('patrolTimeChange')
    this.$event.$off('fullscreenStyleChange')
    this.$event.$off('projectClick')
    this.$event.$off('drawPatrolLine')
    this._lineTimer && clearInterval(this._lineTimer)
    this._lineTimer = null
    this._map && this._map.destroy()
  },
  methods: {
    taskClick (e) {
      const patrolMode = e.patrolMode === 1 ? 'line' : 'point'
      // 如果当前设置未勾选，则把对应图层勾选上
      if (!this.settingOptions.includes(patrolMode)) {
        this.settingOptions.push(patrolMode)
        this.controlOverlay([patrolMode], true)
      }
      if (!this.settingOptions.includes('XC-0')) {
        this.settingOptions.push('XC-0')
        this.controlOverlay(['XC-0'], true)
      }
      if (!this.settingOptions.includes('XC-1')) {
        this.settingOptions.push('XC-1')
        this.controlOverlay(['XC-1'], true)
      }
      if (!this.settingOptions.includes('XC-2')) {
        this.settingOptions.push('XC-2')
        this.controlOverlay(['XC-2'], true)
      }
      const lines = this._projectLineGroup.getOverlays()
      const points = this._projectPointGroup.getOverlays()
      this.resetMap()
      if (patrolMode === 'line') {
        const current = lines.find(item => {
          return item.getExtData().planeId === e.planeId
        })
        current.setOptions({
          strokeWeight: 8,
          zIndex: 100
        })
        this._map.setFitView(current, true)
      } else {
        const markers = points.filter(item => {
          return item.getExtData().planeIds.includes(e.planeId)
        })
        markers.forEach(e => {
          const item = e.getExtData()
          const status = new Map()
          status.set('0', '0')
          status.set('1', '3')
          status.set('2', '1')
          e.setIcon(new AMap.Icon({
            // 图标的取图地址
            image: `${iconUrl + item.facilityIcon}_${status.get(item.planeStatus)}.png`,
            size: [45, 45],
            imageSize: [45, 45]
          }))
        })
        this._map.setFitView(markers, true)
      }
    },
    taskLoadCompleted (res) {
      this.resetMap()
      if (!this._projectLineGroup && !this._projectPointGroup) {
        this._projectLineGroup = new AMap.OverlayGroup()
        this._projectPointGroup = new AMap.OverlayGroup()
        this._projectIssueYGroup = new AMap.OverlayGroup()
        this._projectIssueNGroup = new AMap.OverlayGroup()
        this._map.add(this._projectLineGroup)
        this._map.add(this._projectPointGroup)
        this._map.add(this._projectIssueYGroup)
        this._map.add(this._projectIssueNGroup)
      } else {
        this._projectLineGroup.clearOverlays()
        this._projectPointGroup.clearOverlays()
        this._projectIssueYGroup.clearOverlays()
        this._projectIssueNGroup.clearOverlays()
      }

      const polylines = []
      const polylineMarkers = []
      res.planeList.forEach(item => {
        if (item.lineList && item.lineList.length > 2) {
          const colors = new Map()
          colors.set('0', '#A3A1A1')
          colors.set('1', '#FF9B00')
          colors.set('2', '#52C41A')
          const polyline = new AMap.Polyline({
            path: item.lineList.map(line => {
              return [line.longitude, line.latitude]
            }),
            cursor: 'pointer',
            strokeColor: colors.get(item.planeStatus) || '#FF9B00',
            strokeOpacity: 1,
            strokeWeight: 6,
            extData: {
              planeId: item.planeId,
              planeStatus: item.planeStatus
            }
          })
          polyline.on('click', e => {
            this.resetMap()
            e.target.setOptions({
              strokeWeight: 8
            })
            this.showPointWindow = true
            this._map.setCenter(e.lnglat)
            this.taskParams.planId = item.planeId
            this.taskParams.planIds = item.planeId
            this.taskParams.facilityId = item.facilityId
            this.taskParams.patrolMode = 1
          })
          polylines.push(polyline)
          let icon = ''
          if (item.planeStatus === '2') {
            icon = qian1
          } else if (item.taskStatus === '1') {
            icon = qian2
          } else {
            icon = qian4
          }
          ;[item.lineList[0], item.lineList[item.lineList.length - 1]].map(line => {
            polylineMarkers.push(new AMap.Marker({
              position: [line.longitude, line.latitude],
              icon: icon,
              anchor: 'center',
              extData: {
                planeId: item.planeId,
                planeStatus: item.planeStatus
              }
            }))
          })
        }
      })
      this._projectLineGroup.addOverlays(polylines)
      this._projectLineGroup.addOverlays(polylineMarkers)

      const pointMarkers = res.pointList.map(item => {
        const status = new Map()
        status.set('0', '0')
        status.set('1', '3')
        status.set('2', '1')
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: `${iconUrl + item.facilityIcon}_${status.get(item.planeStatus)}.png`,
            size: [45, 45],
            imageSize: [45, 45]
          }),
          anchor: 'bottom-center',
          position: [+item.longitude, +item.latitude],
          extData: { ...item }
        })
        marker.on('click', e => {
          this.resetMap()
          const status = new Map()
          status.set('0', '0')
          status.set('1', '3')
          status.set('2', '1')
          e.target.setIcon(new AMap.Icon({
            // 图标的取图地址
            image: `${iconUrl + item.facilityIcon}_${status.get(item.planeStatus)}.png`,
            size: [55, 55],
            imageSize: [55, 55]
          }))
          this._map.setCenter(e.lnglat)
          this.showPointWindow = true
          this.taskParams.planeId = ''
          this.taskParams.planIds = item.planeIds
          this.taskParams.facilityName = item.facilityName
          this.taskParams.facilityId = item.facilityId
          this.taskParams.patrolMode = 2
        })
        return marker
      })
      this._projectPointGroup.addOverlays(pointMarkers)

      const markersY = []
      const markersN = []
      res.issueList.forEach(item => {
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            image: item.issueStatus === 3 ? issueIconY : issueIconN,
            size: [36, 48],
            imageSize: [36, 48]
          }),
          anchor: 'bottom-center',
          position: [+item.longitude, +item.latitude],
          extData: { ...item }
        })
        marker.on('click', e => {
          const params = e.target.getExtData()
          console.log('params', params)
          this.resetMap()
          e.target.setIcon(new AMap.Icon({
            image: item.issueStatus === 3 ? issueIconY : issueIconN,
            size: [54, 72],
            imageSize: [54, 72]
          }))
          this.currentIssueId = params.issueId
          this.patrolVisible = true
          this.$nextTick(() => {
            this._infoWindow = new AMap.InfoWindow({
              content: this.$refs.question.$el,
              offset: new AMap.Pixel(0, -45)
            })
            this._infoWindow.open(this._map, marker.getPosition())
          })
          // this.orderVisible = true
        })
        if (item.issueStatus === 3) {
          markersY.push(marker)
        } else {
          markersN.push(marker)
        }
      })
      console.log('执行了执行了')
      this._projectIssueYGroup.addOverlays(markersY)
      this._projectIssueNGroup.addOverlays(markersN)
      if (!this.settingOptions.includes('line')) {
        this._projectLineGroup.hide()
      } else {
        const lineOverlays = this._projectLineGroup.getOverlays()
        if (!this.settingOptions.includes('XC-0')) {
          lineOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
            overlay.hide()
          })
        }
        if (!this.settingOptions.includes('XC-1')) {
          lineOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
            overlay.hide()
          })
        }
        if (!this.settingOptions.includes('XC-2')) {
          lineOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
            overlay.hide()
          })
        }
      }
      if (!this.settingOptions.includes('point')) {
        this._projectPointGroup.hide()
      } else {
        const pointOverlays = this._projectPointGroup.getOverlays()
        if (!this.settingOptions.includes('XC-0')) {
          pointOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
            overlay.hide()
          })
        }
        if (!this.settingOptions.includes('XC-1')) {
          pointOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
            overlay.hide()
          })
        }
        if (!this.settingOptions.includes('XC-2')) {
          pointOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
            overlay.hide()
          })
        }
      }
      if (!this.settingOptions.includes('WT-N')) {
        this._projectIssueNGroup.hide()
      }
      if (!this.settingOptions.includes('WT-Y')) {
        this._projectIssueYGroup.hide()
      }
    },
    workOrderClick (data) {
      this.orderStyle = false
      console.log('工单data', data)
      // 如果当前设置未勾选，则把对应图层勾选上
      const type = data.orderStatus === '2' ? 'GD-Y' : 'GD-N'
      if (!this.settingOptions.includes(type)) {
        this.settingOptions.push(type)
        this.controlOverlay([type], true)
      }
      if (data.longitude) {
        const markers = this._map.getAllOverlays('marker')
        const marker = markers.find(marker => marker.getExtData().orderId === data.orderId)
        const item = marker.getExtData()
        this.resetMap() // 重置
        marker.setIcon(new AMap.Icon({
          image: item.orderStatus === '2' ? issueIconY : issueIconN,
          size: [54, 72],
          imageSize: [54, 72]
        }))
        this._map.setFitView(marker)
      } else {
        this.resetMap()
        this.orderStyle = true
        this.currentOrderId = data.orderId
        this.orderVisible = true
        // this.$nextTick(() => {
        //   this._infoWindow = new AMap.InfoWindow({
        //     content: this.$refs.order.$el,
        //     offset: new AMap.Pixel(0, -45)
        //   })
        //   this._infoWindow.open(this._map, marker.getPosition())
        // })
      }
    },
    workOrderCompleted (data) {
      if (!this._orderNGroup && !this._orderYGroup) {
        this._orderYGroup = new AMap.OverlayGroup()
        this._orderNGroup = new AMap.OverlayGroup()
        this._map.add(this._orderYGroup)
        this._map.add(this._orderNGroup)
      } else {
        this._orderYGroup.clearOverlays()
        this._orderNGroup.clearOverlays()
      }
      const markersY = []
      const markersN = []
      data.filter(item => !!item.longitude).forEach(item => {
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            image: item.orderStatus === '2' ? issueIconY : issueIconN,
            size: [36, 48],
            imageSize: [36, 48]
          }),
          anchor: 'bottom-center',
          position: [+item.longitude, +item.latitude],
          extData: { ...item }
        })
        marker.on('click', e => {
          const params = e.target.getExtData()
          this.resetMap()
          e.target.setIcon(new AMap.Icon({
            image: item.orderStatus === '2' ? issueIconY : issueIconN,
            size: [54, 72],
            imageSize: [54, 72]
          }))
          this.currentOrderId = params.orderId
          this.orderVisible = true
          console.log(this.$refs.order)
          this.$nextTick(() => {
            this._infoWindow = new AMap.InfoWindow({
              content: this.$refs.order.$el,
              offset: new AMap.Pixel(0, -45)
            })
            this._infoWindow.open(this._map, marker.getPosition())
          })
        })
        if (item.orderStatus === '2') {
          markersY.push(marker)
        } else {
          markersN.push(marker)
        }
      })
      this._orderYGroup.addOverlays(markersY)
      this._orderNGroup.addOverlays(markersN)
      if (!this.settingOptions.includes('GD-N')) {
        this._orderNGroup.hide()
      }
      if (!this.settingOptions.includes('GD-Y')) {
        this._orderYGroup.hide()
      }
    },
    signClick (data) {
      console.log('datadatadata', data)
      if (!this.settingOptions.includes('sign')) {
        this.settingOptions.push('sign')
        this.controlOverlay(['sign'], true)
      }
      this.resetMap()
      let items = []
      if (data.signType) {
        items = this._signGroup.getOverlays().filter(item => {
          const params = item.getExtData()
          return params.attendanceId === data.attendanceId && params.signType === data.signType
        })
      } else {
        items = this._signGroup.getOverlays().filter(item => {
          const params = item.getExtData()
          return params.attendanceId === data.attendanceId
        })
      }
      items.forEach(item => {
        item.setIcon(new AMap.Icon({
          image: qiandao,
          size: [54, 63],
          imageSize: [54, 63]
        }))
      })
      this._map.setFitView(items, true)
    },
    signCompleted (data) {
      if (!this._signGroup) {
        this._signGroup = new AMap.OverlayGroup()
        this._map.add(this._signGroup)
      } else {
        this._signGroup.clearOverlays()
      }
      const workers = []
      data.workerList.forEach(item => {
        if (item.onLongitude && item.onLatitude) {
          workers.push({
            attendanceId: item.attendanceId,
            longitude: item.onLongitude,
            latitude: item.onLatitude,
            signType: '1',
            workerName: item.workerName,
            signImage: item.onSignImage,
            signTime: item.onSignTime
          })
        }
        if (item.offLongitude && item.offLatitude) {
          workers.push({
            attendanceId: item.attendanceId,
            longitude: item.offLongitude,
            latitude: item.offLatitude,
            signType: '2',
            workerName: item.workerName,
            signImage: item.offSignImage,
            signTime: item.offSignTime
          })
        }
      })
      const items = [...workers, ...data.teamList]
      const markers = []
      items.forEach((item, index) => {
        if (item.longitude && item.latitude) {
          const peopleMarker = new AMap.Marker({
            icon: new AMap.Icon({
              image: qiandao,
              size: [36, 42],
              imageSize: [36, 42]
            }),
            zIndex: 100,
            anchor: 'bottom-center',
            position: [+item.longitude, +item.latitude],
            extData: { ...item, index }
          })
          peopleMarker.on('click', e => {
            this.resetMap()
            const params = e.target.getExtData()
            const target = this._signGroup.getOverlays().find(overlay => {
              const data = overlay.getExtData()
              return data.index === params.index && data.type === 'popup'
            })
            this.currentSign = target.getExtData()
            this.$nextTick(() => {
              this.$refs.sign.style.display = 'flex'
              target.setContent(this.$refs.sign)
              e.target.setIcon(new AMap.Icon({
                image: qiandao,
                size: [54, 63],
                imageSize: [54, 63]
              }))
              target.show()
            })
          })
          markers.push(peopleMarker)
          markers.push(new AMap.Marker({
            position: [+item.longitude, +item.latitude],
            anchor: 'bottom-center',
            offset: [-90, -90],
            visible: false,
            cursor: 'default',
            zIndex: 101,
            content: this.$refs.sign,
            // content: `<div class="sing-info-tip">
            //             <img src="${item.signImage}"/>
            //             <div class="right">
            //               ${item.signNum ? `<span>班组：${item.teamName}</span>` : `<span>姓名：${item.workerName}</span>`}
            //               <span>签到：${item.signType === '1' ? '上班' : '下班'}</span>
            //               ${item.signNum ? `<span>人数：${item.signNum || '--'}</span>` : ''}
            //               <span>时间：${item.signTime || '--'}</span>
            //             </div>
            //           </div>`,
            extData: {
              attendanceId: item.attendanceId,
              type: 'popup',
              index,
              ...item
            }
          }))
        }
      })
      this._signGroup.addOverlays(markers)
      if (!this.settingOptions.includes('sign')) {
        this._signGroup.hide()
      }
    },
    controlOverlay (types, status) {
      if (!this.currentProjectId) { // 全部项目模式下不禁止图层处理操作
        return
      }
      const lineOverlays = this._projectLineGroup.getOverlays()
      const pointOverlays = this._projectPointGroup.getOverlays()
      types.forEach(type => {
        console.log('type', type)
        switch (type) {
          case 'line':
            if (status) {
              this._projectLineGroup.show()
              lineOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-0') ? 'show' : 'hide']()
              })
              lineOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-1') ? 'show' : 'hide']()
              })
              lineOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-2') ? 'show' : 'hide']()
              })
            } else {
              this._projectLineGroup.hide()
            }
            break
          case 'point':
            if (status) {
              this._projectPointGroup.show()
              pointOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-0') ? 'show' : 'hide']()
              })
              pointOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-1') ? 'show' : 'hide']()
              })
              pointOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
                overlay[this.settingOptions.includes('XC-2') ? 'show' : 'hide']()
              })
            } else {
              this._projectPointGroup.hide()
            }
            break
          case 'XC-2':
            if (this.settingOptions.includes('line')) {
              lineOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            if (this.settingOptions.includes('point')) {
              pointOverlays.filter(item => item.getExtData().planeStatus === '2').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            break
          case 'XC-1':
            if (this.settingOptions.includes('line')) {
              lineOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            if (this.settingOptions.includes('point')) {
              pointOverlays.filter(item => item.getExtData().planeStatus === '1').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            break
          case 'XC-0':
            if (this.settingOptions.includes('line')) {
              lineOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            if (this.settingOptions.includes('point')) {
              pointOverlays.filter(item => item.getExtData().planeStatus === '0').forEach(overlay => {
                overlay[status ? 'show' : 'hide']()
              })
            }
            break
          case 'WT-Y':
            if (status) {
              this._projectIssueYGroup.show()
            } else {
              this._projectIssueYGroup.hide()
            }
            break
          case 'WT-N':
            if (status) {
              this._projectIssueNGroup.show()
            } else {
              this._projectIssueNGroup.hide()
            }
            break
          case 'GD-Y':
            if (status) {
              this._orderYGroup.show()
            } else {
              this._orderYGroup.hide()
            }
            break
          case 'GD-N':
            if (status) {
              this._orderNGroup.show()
            } else {
              this._orderNGroup.hide()
            }
            break
          case 'sign':
            if (status) {
              this._signGroup.show()
            } else {
              this._signGroup.hide()
            }
            break
          case 'grid':
            if (status) {
              this._projectGridGroup.show()
            } else {
              this._projectGridGroup.hide()
            }
            break
          default:
            break
        }
      })
    },
    layerOptionsChange (val) {
      this.layerOptions = [val[val.length - 1]]
      if (this.layerOptions.includes('1')) {
        this._wxLayer.show()
      } else {
        this._wxLayer.hide()
      }
    },
    handleCheckAllChange (val) {
      this.settingOptions = val ? ['line', 'point', 'XC-0', 'XC-1', 'XC-2', 'WT-Y', 'WT-N', 'GD-N', 'GD-Y', 'sign', 'grid'] : []
      this.isIndeterminate = false
    },
    handleCheckChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === 11
      this.isIndeterminate = checkedCount > 0 && checkedCount < 11
    },
    loadProjectInfo () {
      // 暂时不用
    },
    calculateGeofenceCenter (coordinates) {
      if (!coordinates || coordinates.length === 0) {
        return null
      }
      // 创建 Turf.js 多边形对象
      const polygon = helpers.polygon([coordinates])
      // 计算多边形的中心点
      const center = turf.centerOfMass(polygon)
      // 提取中心点经纬度坐标
      const centerCoordinates = center.geometry.coordinates
      return { latitude: centerCoordinates[1], longitude: centerCoordinates[0] }
    },
    loadProjectFence () {
      this.$axios.get(this.$apis.project.selectProjectElectronicFence, {
        projectId: this.currentProjectId
      }).then(res => {
        console.log('projectId', res)
        res[0].areaList.forEach(item => {
          const coordinates = item.locationList.map(item => [+item.longitude, +item.latitude])
          coordinates.push(coordinates[0])
          const location = this.calculateGeofenceCenter(coordinates)
          Object.assign(item, location)
        })
        console.log(res[0].areaList)
        const markers = res[0].areaList.map(item => {
          return new AMap.Marker({
            position: [item.longitude, item.latitude],
            anchor: 'bottom-center',
            icon: new AMap.Icon({
              size: [16, 20],
              image: hongdian,
              imageSize: [16, 20],
              cursor: 'default'
            })
          })
        })
        const markersInfo = res[0].areaList.map(item => {
          const marker = new AMap.Marker({
            position: [item.longitude, item.latitude],
            anchor: 'bottom-center',
            offset: [0, -25],
            zIndex: 100,
            cursor: 'default',
            content: `<div class="area-info-tip">
                        <span>${item.areaName || '--'}</span>
                        <span>负责人：${item.headUserName || '--'}</span>
                      </div>`,
            extData: {
              projectAreaId: item.projectAreaId
            }
          })
          return marker
        })
        const projectPolygons = getPolygons(res[0].locationList)
        const polygons = projectPolygons.map(item => {
          const polygon = new AMap.Polygon({
            path: item[1].map(location => {
              return new AMap.LngLat(location.longitude, location.latitude)
            }),
            bubble: true,
            strokeColor: '#54A2E9',
            strokeOpacity: 1,
            strokeWeight: 6,
            strokeStyle: 'dashed',
            fillColor: 'rgba(121, 189, 232, 1)',
            fillOpacity: 0,
            extData: {
              fenceCode: item[0]
            }
          })
          return polygon
        })
        const polygons2 = res[0].areaList.map(item => {
          const arr = getPolygons(item.locationList)
          return arr.map(item1 => {
            const polygon = new AMap.Polygon({
              path: item1[1].map(location => {
                return new AMap.LngLat(location.longitude, location.latitude)
              }),
              bubble: true,
              strokeColor: '#36CBCB',
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: '#36CBCB',
              fillOpacity: 0.3,
              extData: {
                fenceCode: item1[0]
              }
            })
            return polygon
          })
        })
        this._projectGridGroup = new AMap.OverlayGroup([...(polygons2.flat()), ...markers, ...markersInfo])
        if (!this.settingOptions.includes('grid')) {
          this._projectGridGroup.hide()
        }
        this._projectOverlayGroup = new AMap.OverlayGroup([...polygons])
        this._map.add(this._projectOverlayGroup)
        this._map.add(this._projectGridGroup)
        this._map.setFitView(polygons, true)
      })
    },
    loadFence () {
      this.$axios.get(this.$apis.project.selectProjectElectronicFence).then(res => {
        this._polygonsProject = res // 存放原始数据，用于记录fenceCode
        const data = getPolygons(res.map(item => {
          return item.locationList || []
        }).reduce((prev, next) => {
          return prev.concat(next)
        }, []))
        const markers = res.filter(item => item.locationList && item.locationList.length).map(item => {
          return new AMap.Marker({
            position: [item.longitude, item.latitude],
            anchor: 'bottom-center',
            icon: new AMap.Icon({
              size: [16, 20],
              image: hongdian,
              imageSize: [16, 20],
              cursor: 'default'
            })
          })
        })
        const markersInfo = res.filter(item => item.locationList && item.locationList.length).map(item => {
          const marker = new AMap.Marker({
            position: [item.longitude, item.latitude],
            anchor: 'bottom-center',
            offset: [0, -25],
            content: `<div class="project-info-tip">
                        <span>${item.projectName}</span>
                        <span>养护面积：${item.maintAcreage || 0}㎡</span>
                        <span>养护周期：${item.maintEndTime}</span>
                      </div>`,
            extData: {
              projectId: item.projectId
            }
          })
          marker.on('click', e => {
            this.currentProjectId = e.target.getExtData().projectId
            const projectIndex = this.projectList.findIndex(item => item.projectId === this.currentProjectId)
            if (projectIndex === this.currentIndex) {
              this.loadProjectFence()
            }
            this.currentIndex = this.projectList.findIndex(item => item.projectId === this.currentProjectId)
            this.$nextTick(() => {
              document.getElementById(this.activeProjectId).scrollIntoView({ behavior: 'smooth' })
            })
          })
          return marker
        })
        const polygons = data.map(item => {
          const polygon = new AMap.Polygon({
            path: item[1].map(location => {
              return new AMap.LngLat(location.longitude, location.latitude)
            }),
            strokeColor: '#54A2E9',
            strokeOpacity: 1,
            strokeWeight: 2,
            bubble: true,
            strokeStyle: 'dashed',
            fillColor: 'rgba(121, 189, 232, 1)',
            fillOpacity: 0.49,
            extData: {
              fenceCode: item[0]
            }
          })
          polygon.on('click', e => {
            const data = e.target.getExtData()
            const project = this._polygonsProject.find(item => {
              return item.locationList && item.locationList.length && item.locationList.some(location => location.fenceCode === data.fenceCode)
            })
            this.currentProjectId = project.projectId
            const projectIndex = this.projectList.findIndex(item => item.projectId === this.currentProjectId)
            if (projectIndex === this.currentIndex) {
              this.loadProjectFence()
            }
            this.currentIndex = this.projectList.findIndex(item => item.projectId === this.currentProjectId)
            this.$nextTick(() => {
              document.getElementById(this.activeProjectId).scrollIntoView({ behavior: 'smooth' })
            })
          })
          return polygon
        })
        this._allProjectOverlayGroup = new AMap.OverlayGroup([...markers, ...markersInfo, ...polygons])
        this._map.add(this._allProjectOverlayGroup)
        this._map.setFitView(polygons, true)
      })
    },
    prevClick () {
      if (this.currentIndex > -1 && this.projectList.length > 1) {
        this.currentIndex--
        if (this.projectList[this.currentIndex].projectId) {
          this.$event.$emit('projectClick', this.projectList[this.currentIndex].projectId)
        }
        document.getElementById(this.activeProjectId).scrollIntoView({ behavior: 'smooth' })
      }
    },
    nextClick () {
      if (this.currentIndex < this.projectList.length - 1) {
        this.currentIndex++
        if (this.projectList[this.currentIndex].projectId) {
          this.$event.$emit('projectClick', this.projectList[this.currentIndex].projectId)
        }
        document.getElementById(this.activeProjectId).scrollIntoView({ behavior: 'smooth' })
      }
    },
    projectClick (index) {
      this.currentIndex = index
      let id = 'all'
      if (this.currentIndex > -1) {
        id = this.projectList[this.currentIndex].projectId
      }
      this.$event.$emit('projectClick', id)
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    },
    resetMap () {
      if (this._projectLineGroup) {
        const lines = this._projectLineGroup.getOverlays()
        lines.forEach(line => {
          line.setOptions({
            strokeWeight: 6
          })
        })
      }
      if (this._projectPointGroup) {
        const points = this._projectPointGroup.getOverlays()
        points.forEach(point => {
          const data = point.getExtData()
          const status = new Map()
          status.set('0', '0')
          status.set('1', '3')
          status.set('2', '1')
          point.setIcon(new AMap.Icon({
            image: `${iconUrl + data.facilityIcon}_${status.get(data.planeStatus)}.png`,
            size: [45, 45],
            imageSize: [45, 45]
          }))
        })
      }
      if (this._orderYGroup && this._orderNGroup) {
        const orderY = this._orderYGroup.getOverlays()
        const orderN = this._orderNGroup.getOverlays()
          ;[...orderY, ...orderN].forEach(item => {
          const data = item.getExtData()
          item.setIcon(new AMap.Icon({
            image: data.orderStatus === '2' ? issueIconY : issueIconN,
            size: [36, 48],
            imageSize: [36, 48]
          }))
        })
      }
      if (this._projectIssueYGroup && this._projectIssueNGroup) {
        const issueY = this._projectIssueYGroup.getOverlays()
        const issueN = this._projectIssueNGroup.getOverlays()
          ;[...issueY, ...issueN].forEach(item => {
          const data = item.getExtData()
          item.setIcon(new AMap.Icon({
            image: data.issueStatus === 3 ? issueIconY : issueIconN,
            size: [36, 48],
            imageSize: [36, 48]
          }))
        })
      }
      if (this._signGroup) {
        this._signGroup.getOverlays().forEach(overlay => {
          if (overlay.getExtData().type === 'popup') {
            overlay.hide()
          } else {
            overlay.setIcon(new AMap.Icon({
              image: qiandao,
              size: [36, 42],
              imageSize: [36, 42]
            }))
          }
        })
      }
      if (this.showPointWindow) {
        this.showPointWindow = false
      }
      if (this.orderVisible) {
        this.orderVisible = false
      }
      this._trackLineGroup && this._trackLineGroup.clearOverlays()
      this._infoWindow && this._infoWindow.close()
      // this._trackLineGroup && this._trackLineGroup.clearOverlays()
    },
    loadMap () {
      return AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        this._wxLayer = new AMap.TileLayer.Satellite({
          visible: false
        })
        this._map = new AMap.Map('map2', {
          zoom: 11,
          doubleClickZoom: false,
          layers: [AMap.createDefaultLayer(), this._wxLayer]
        })

        this._map.on('click', this.resetMap)
      })
    },
    drawLineOrPoints (item) {
      this.getTrack(item)
    },
    /**
     * 请求问题点
     * @param {*} param 参数
     */
    getTrack (param) {
      if (!this._trackLineGroup) {
        this._trackLineGroup = new AMap.OverlayGroup()
      } else {
        this._trackLineGroup.clearOverlays()
      }
      this.$axios.get(this.$apis.patrol.selectTrackList, {
        taskId: param.taskId
      }).then(res => {
        if (res && res.length) {
          const polyline = new AMap.Polyline({
            path: res.map(line => {
              return [line.longitude, line.latitude]
            }),
            strokeColor: '#3592FE',
            strokeOpacity: 1,
            strokeWeight: 4
          })
          // (1未巡查2巡查中4已完成5已逾期6已取消)
          if (param.taskStatus) {
            if ([3, 4].includes(param.taskStatus)) {
              const marker = []
              marker.push(new AMap.Marker({
                position: [res[0].longitude, res[0].latitude],
                icon: require('@/assets/img/patrol/start_finished.svg'),
                anchor: 'center'
              }))
              marker.push(new AMap.Marker({
                position: [res[res.length - 1].longitude, res[res.length - 1].latitude],
                icon: require('@/assets/img/patrol/end_finished.svg'),
                anchor: 'center'
              }))
              this._trackLineGroup.addOverlays(marker)
              this._trackLineGroup.addOverlays([polyline])
              this._map.add(this._trackLineGroup)
            } else {
              // 执行中实时更新点位，一秒获取一次最新点位
              if (!this._lineTimer) {
                this._lineTimer = setInterval(() => {
                  this.getTrack({ taskId: param.taskId })
                }, 1000)
              }
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.project-tip {
  .amap-marker-label {
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 14px 20px 0px rgba(26, 66, 211, 0.5);
    border-radius: 17px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #4A4A4A;
    line-height: 24px;
    text-align: right;
    font-style: normal;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0 20px;
    transform: translate3d(0, -5px, 0);
  }
}

.project-info-tip {
  min-width: 176px;
  max-width: 216px;
  height: 100px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 5%, #FFFFFF 12%, #FFFFFF 89%, #ECECEC 100%);
  box-shadow: 0px 14px 20px 0px rgba(26, 66, 211, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  span {
    &:nth-child(1) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #4A4A4A;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 8px;
    }

    &:nth-child(n + 2) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #4A4A4A;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }
}

.area-info-tip {
  max-width: 200px;
  min-width: 132px;
  height: 62px;
  background: linear-gradient(325deg, #FFFFFF 0%, #EEFFE8 50%, #A9FF9B 100%);
  box-shadow: 0px 14px 20px 0px rgba(26, 66, 211, 0.5);
  border-radius: 10px;
  border: 2px solid #4FB340;
  display: flex;
  flex-direction: column;
  padding: 8px 18px;

  span {
    &:nth-child(1) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #417505;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:nth-child(2) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #4A4A4A;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }
}

.sing-info-tip {
  width: 230px;
  min-width: 190px;
  min-height: 82px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 5%, #FFFFFF 12%, #FFFFFF 89%, #ECECEC 100%);
  box-shadow: 0px 14px 20px 0px rgba(26, 66, 211, 0.5);
  border-radius: 10px;
  display: none;
  align-items: center;
  padding: 10px;

  .el-image {
    width: 44px;
    height: 60px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      width: 160px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #4A4A4A;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<style scoped lang="scss">
.apicture-header {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 7;
  top: 0;
  width: 100%;
  background: url('../../assets/img/apicture/fullscreen_bg.png') 100% 100% no-repeat;
  height: 95px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 34px;
  color: #FFFFFF;
  font-style: normal;
  line-height: 60px;
}

.el-divider {
  margin: 8px 0;
  background-color: #E6E6E6;
}

.item-label {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}

.apicture-content {
  position: relative;
  width: 100%;
  background: #FFFFFF;
}

#map2 {
  width: 100%;
  height: 100%;
}

.tool {
  position: fixed;
  bottom: 20px;
  width: 80px;
  height: 80px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 94%);
  box-shadow: 0px 4px 10px 0px rgba(129, 134, 153, 0.5);
  border-radius: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  cursor: pointer;

  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #24278C;
    line-height: 20px;
    text-align: right;
    font-style: normal;
    margin-top: 5px;
  }

  &.tool1 {
    left: 20px;

    img {
      width: 26px;
      height: 26px;
    }
  }

  &.tool2 {
    right: 110px;

    img {
      width: 29px;
      height: 33px;
    }
  }

  &.tool3 {
    right: 20px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.project-content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  max-width: 883px;
  height: 64px;
  background: linear-gradient(180deg, rgba(0, 38, 145, 0.9) 0%, rgba(6, 32, 60, 0.5) 100%);
  border-radius: 40px 40px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0 26px;

  .items {
    display: flex;
    flex-shrink: 0;

    &.itemslist {
      overflow-x: auto;
      max-width: 580px;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      min-width: 190px;
      height: 64px;
      cursor: pointer;

      &:nth-child(n + 2) {
        margin-left: 50px;
      }

      &.active {
        background: url('../../assets/img/apicture/project-selected.png') no-repeat;
        background-size: 190px 49px;
        background-position: center bottom;
      }

      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 25px;
        text-align: right;
        font-style: normal;
      }
    }
  }
}

.prev {
  width: 32px;
  height: 32px;
  background: url('../../assets/img/apicture/left.png');
  background-size: 32px 32px;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 5px;

  &:hover {
    background: url('../../assets/img/apicture/left-active.png');
    background-size: 32px 32px;
  }
}

.next {
  width: 32px;
  height: 32px;
  background: url('../../assets/img/apicture/right.png');
  background-size: 32px 32px;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 5px;

  &:hover {
    background: url('../../assets/img/apicture/right-active.png');
    background-size: 32px 32px;
  }
}

.tool1-panel {
  position: fixed;
  bottom: 30px;
  left: 110px;
  width: 200px;
  height: 430px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 94%);
  box-shadow: 0px 4px 10px 0px rgba(129, 134, 153, 0.5);
  border-radius: 10px;
  padding: 0 16px 24px;
  z-index: 1000;

  .tabs-header {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;

    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      &:nth-child(n + 2) {
        margin-left: 24px;
      }

      &.active {
        &::after {
          content: '';
          width: 22px;
          height: 2px;
          background: #4A9EFF;
          border-radius: 8px 8px 0px 0px;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        }

        span {
          font-weight: 500;
          color: #24278C;
        }
      }

      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #AAAAB1;
        line-height: 20px;
        text-align: right;
        font-style: normal;
      }
    }

    .tab-content {}
  }
}

.el-checkbox {
  margin-right: 0;
  width: 50%;
  margin-bottom: 4px;

  ::v-deep {
    .el-checkbox__label {
      padding-left: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }
}

.moveWindow {
  position: absolute;
  top: calc(50% - 290px);
  z-index: 9;
  left: calc(50% - 250px);

  &::after {
    position: absolute;
    left: calc(50% - 10px);
    bottom: -21px;
    content: "";
    border: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
}

::v-deep .el-drawer {
  width: 800px !important;

  .el-drawer__header {
    padding: 10px 30px;
    margin-bottom: 0;
    border-bottom: 1px solid #F0F0F0;
  }

  .el-dialog__close {
    &::before {
      font-size: 14px;
    }
  }

  .el-drawer__body {
    position: relative;
    background: #FAFBFB;
  }
}

::v-deep .amap-info-close{
  display: none;
}
.center{
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
</style>
