import { render, staticRenderFns } from "./APicture.vue?vue&type=template&id=9488a364&scoped=true&"
import script from "./APicture.vue?vue&type=script&lang=js&"
export * from "./APicture.vue?vue&type=script&lang=js&"
import style0 from "./APicture.vue?vue&type=style&index=0&id=9488a364&prod&lang=scss&"
import style1 from "./APicture.vue?vue&type=style&index=1&id=9488a364&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9488a364",
  null
  
)

export default component.exports