<template>
  <div class="pointWindow">
    <div class="menu">
      <div class="menuItem" v-for="(item, index) in tabs" :key="index" :class="{ active: tabIndex === index}" @click="chooseTabIndex(index)">
        <span class="name">{{item}}</span>
      </div>
    </div>
    <div class="content">
      <!-- 任务信息-->
      <template v-if="tabIndex === 0">
        <div class="title">{{info.patrolMode && info.patrolMode === 1 ? taskList.length ? taskList[0].planName : '' : info.facilityName }}</div>
        <div class="ctt">
          <div class="task" v-for="(task, index) in taskList" :key="index">
            <div class="line" v-if="task.patrolMode === 2">
              <span class="name">任务名称：</span>
              <span class="val">{{task.planName || ''}}</span>
            </div>
            <div class="line">
              <span class="name">巡查方式：</span>
              <span class="val">
                <svg v-if="task.patrolMode === 1" class="global-icon" aria-hidden="true">
                    <use xlink:href="#icon-anxianxuncha"></use>
                </svg>
                <svg v-else class="global-icon" aria-hidden="true">
                    <use xlink:href="#icon-andianxuncha"></use>
                </svg>
                {{[1, 2].includes(task.patrolMode) ? patrolModeList[task.patrolMode - 1] : ''}}
              </span>
            </div>
            <div class="line">
              <span class="name">巡查类型：</span>
              <span class="val button">{{[1, 2, 3].includes(task.planType) ? planTypeList[task.planType - 1] : ''}}</span>
            </div>
            <div class="line">
              <span class="name">巡查频次：</span>
              <span class="val">{{task.patrolFrequency}}次{{[1, 2, 3].includes(task.planType) ? '/' + ['日', '周', '月'][task.planType - 1] : ''}}</span>
            </div>
            <div class="line">
              <span class="name">任务指派人：</span>
              <span class="val">{{task.createUserName || ''}}</span>
            </div>
            <div class="line">
              <span class="name">任务执行人：</span>
              <span class="val">{{task.executeUserName || ''}}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- 巡查记录-->
      <template v-else-if="tabIndex === 1">
        <div class="title">
          <span>巡查记录：{{logInfo.taskCount || 0}}</span>
          <span>已完成巡查：{{logInfo.ywcTaskCount || 0}}</span>
        </div>
        <div class="ctt">
          <el-table class="global-table" :data="logList" :max-height="200">
            <el-table-column label="任务名称" show-overflow-tooltip prop="taskName" min-width="100" align="center">
              <template v-slot="{ row }">
                <div class="div_cell" @click="toTaskDetial(row)">{{row.taskName || ''}}</div>
              </template>
            </el-table-column>
            <el-table-column v-if="info.patrolMode === 1" label="状态" prop="taskStatus" align="center">
              <template v-slot="{ row }">
                <span
                  class="statusBlock"
                  v-if="[1, 2, 4].includes(row.taskStatus)"
                  :class="{
                  'green': row.taskStatus === 4,
                  'blue': row.taskStatus === 2,
                  'gray': row.taskStatus === 1 }">
                  {{ taskStatusList[row.taskStatus - 1]}}
                </span>
              </template>
            </el-table-column>
            <el-table-column v-else label="点位状态" prop="pointStatus" align="center">
              <template v-slot="{ row }">
                <span
                  class="statusBlock"
                  v-if="[1, 2].includes(row.pointStatus)"
                  :class="{ 'green': row.pointStatus === 1,
                  'gray': row.taskStatus === 2 }">
                  {{ pointStatusList[row.pointStatus - 1]}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="已处理/上报问题" show-overflow-tooltip prop="issueCount" width="120" align="center">
              <template v-slot="{ row }">
                <span v-if="!row.issueCount || row.issueCount === 0">0/0</span>
                <!-- <div v-else type="text" @click="showIssue(row)">{{(row.yclIssueCount || 0) + '/' + (row.issueCount || 0)}}</div> -->
                <div v-else type="text">{{(row.yclIssueCount || 0) + '/' + (row.issueCount || 0)}}</div>
              </template>
            </el-table-column>
            <el-table-column v-if="info.patrolMode === 1" label="巡查轨迹" align='center'>
              <template v-slot = "{ row }">
                <el-button v-if="[2, 4].includes(row.taskStatus)" type="text" @click="viewTrack(row)">查看轨迹</el-button>
                <div v-else>查看轨迹</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 巡查要素-->
      <template v-else-if="tabIndex === 2">
        <div class="ctt h100">
          <div class="elementItem" v-for="(ele, index) in elementList" :key="index">
            <div class="title">{{ele.elementName || ''}}</div>
            <div class="eleCtt">
              <template v-for="(item, ind) in ele.standardList">
                <p :key="item.standardId">{{ind + 1}}、{{item.standardContent || ''}}</p>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>

    <el-dialog title="详情" :append-to-body="false" :modal="true" :modal-append-to-body="false" :visible.sync="taskVisible" width="1200px">
      <task-detail :current-task-id="currentTaskId"></task-detail>
    </el-dialog>
  </div>
</template>

<script>
import TaskDetail from '@/views/patrol/task/TaskDetail'

export default {
  name: 'PointWindow',
  components: {
    TaskDetail
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: '',
          facilityId: '',
          patrolMode: '',
          planId: '',
          planIds: ''
        }
      }
    }
  },
  watch: {
    info: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  data () {
    return {
      tabs: ['任务信息', '巡查记录', '巡查要素'],
      tabIndex: 0,
      patrolModeList: ['线路', '点位'],
      planTypeList: ['日巡查', '周巡查', '月巡查'],
      taskStatusList: ['待执行', '执行中', '未完成', '已完成', '已逾期', '已取消'],
      pointStatusList: ['已完成', '待执行'],
      taskList: [],
      logInfo: {},
      logList: [],
      elementList: [],
      taskVisible: false,
      currentTaskId: ''
    }
  },
  created () {
    // 1
  },
  destroyed () {
    // 1
  },
  methods: {
    init () {
      this.tabIndex = 0
      this.taskList = []
      this.logInfo = {}
      this.logList = []
      this.elementList = []
      this.getTaskInfo()
    },
    chooseTabIndex (index) {
      this.tabIndex = index
      if (index === 1 && !this.logList.length) {
        this.getPatrolLog()
      } else if (index === 2 && !this.elementList.length) {
        this.getPatrolElement()
      }
    },
    getTaskInfo () {
      this.$axios.get(this.$apis.patrol.selectPlaneByList, {
        planIds: this.info.planIds
      }).then(res => {
        this.taskList = res.list || []
      })
    },
    getPatrolLog () {
      this.$axios.get(this.$apis.patrol.selectLinePoint, {
        ...this.info
      }).then(res => {
        this.logList = res.taskList || []
        this.logInfo = {
          taskCount: res.taskCount,
          ywcTaskCount: res.ywcTaskCount
        }
      })
    },
    getPatrolElement () {
      const info = this.info.patrolMode === 1 ? { planId: this.info.planId } : { facilityId: this.info.facilityId }
      this.$axios.get(this.$apis.patrol.selectElementByList, {
        ...info
      }).then(res => {
        this.elementList = res.list || []
      })
    },
    // 查看问题
    showIssue (row) {
      let info = {
        patrolMode: this.info.patrolMode,
        taskId: row.taskId
      }
      if (this.info.patrolMode === 2) {
        info = {
          ...info,
          facilityId: this.info.facilityId
        }
      }
      this.$emit('drawIssue', info)
    },
    // 查看详情
    toTaskDetial (row) {
      console.log(row)
      this.currentTaskId = row.taskId
      this.taskVisible = true
      // this.$router.push({ name: 'patrolTaskDetail', params: { id: row.planId, taskId: row.taskId } })
    },
    // 查看轨迹
    viewTrack (row) {
      // 关闭图层展示轨迹线
      this.$event.$emit('drawPatrolLine', { taskId: row.taskId, taskStatus: row.taskStatus })
    }
  }
}
</script>

<style scoped lang="scss">

.pointWindow {
  position: relative;
  width: 500px;
  height: 280px;
  margin: -1px;
  .menu {
    position: absolute;
    top: 0px;
    left: -32px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .menuItem {
      width: 32px;
      height: 80px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      background: #F4F7FA;
      border: 1px solid #DBDFE6;
      padding: 12px 8px;
      &:not(:last-child) {
        border-bottom-width: 0;
      }
      &.active {
        color: #FFFFFF;
        border-color: #1AD1B0;
        background: #1AD1B0;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    .title {
      width: 100%;
      height: 44px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      padding: 12px 20px;
      background: #F4F7FA;
      span:not(:first-child) {
        padding-left: 30px;
      }
    }
    .ctt {
      width: calc(100% - 20px);
      height: 204px;
      padding-right: 20px;
      margin: 16px 20px;
      overflow-y: auto;
      &.h100 {
        height: calc(100% - 32px);
      }
      .line {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 10px;
        .val {
          color: #666666;
          .global-icon {
            position: relative;
            color: #5588A3;
          }
          &.button {
            display: inline-block;
            width: 58px;
            height: 22px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            text-align: center;
            background: #F9F9F9;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
          }
        }
      }
      .task {
        padding-bottom: 2px;
        &:not(:first-child) {
          padding-top: 12px;
          border-top: 1px solid #DDDDDD;
        }
      }
      .div_cell{
        padding: 8px 0;
        color: #1ad1b0;
        cursor: pointer;
      }
      ::v-deep {
        .el-table {
          color: #A3A1A1;
          .el-table__cell, .cell {
            padding: 0;
          }
        }
        .el-table__header th{
          color: #666666;
        }
        .global-table .el-button--text {
          padding: 17px 15px;
          width: 100%;
          height: 100%;
        }
      }
      .elementItem {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .title, .eleCtt {
          padding: 12px 20px;
          border: 1px solid #EAEEF5;
          &.eleCtt {
            p {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 22px;
              color: #333;
              margin: 0;
            }
            border-top-width: 0;
          }
        }
      }
    }
    .statusBlock {
      display: inline-block;
      width: 74px;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: #A3A1A1;
      text-align: center;
      border-radius: 4px;
      background: rgba(163, 161, 161, 0.1);
      &.green {
        color: #52C41A;
        background: rgba(82, 196, 26, 0.1);
      }
      &.blue {
        color: #3592FE;
        background: rgba(53, 146, 254, 0.1);
      }
    }
  }
}
</style>
