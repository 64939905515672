
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class CustomDialog extends Vue {
  @Prop() private title!: string
  @Prop() private defaultDate!: string

  day: any = null
  week: any = null
  date: any = null
  dateName = ''
  type = 'week'
  dateFormat = ''

  @Watch('defaultDate', { immediate: true })
  listenDefaultDate (val: string) {
    console.log(val)
    this.dateFormat = ''
    this.type = val
    if (val === 'day') {
      this.day = this.$dayjs().format('YYYY-MM-DD')
      this.changeDay()
    } else if (val === 'week') {
      this.week = this.$dayjs()
      this.changeWeek()
    } else if (val === 'month') {
      this.date = this.$dayjs().format('MM')
      this.change()
    }
    this.dateName = this.getDateName()
  }

  // get dateFormat () {
  //   switch (this.type) {
  //     case 'day':
  //     case 'week':
  //       return this.getDate(this.type, 'MM.DD', '-', this.week)
  //     case 'month':
  //       return this.getDate(this.type, 'MM.DD', '-', this.date)
  //     default:
  //       return ''
  //   }
  // }

  pickerOptions = {
    firstDayOfWeek: 1,
    shortcuts: [{
      text: '日',
      onClick: () => {
        this.setType('day')
      }
    }, {
      text: '周',
      onClick: () => {
        this.setType('week')
      }
    }, {
      text: '月',
      onClick: () => {
        this.setType('month')
      }
    }]
  }

  getDateName () {
    switch (this.type) {
      case 'day':
        return '日'
      case 'week':
        return '周'
      case 'month':
        return '月'
      default:
        return ''
    }
  }

  setType (type: string) {
    this.$nextTick(() => {
      this.type = type
      setTimeout(() => {
        ;(this.$refs[type] as any).focus()
      }, 0)
    })
  }

  getDate (type: string, format: string, formattype: string, whichMonth?: string) {
    this.dateName = this.getDateName()
    switch (type) {
      case 'day':
        return this.$dayjs(this.day).format(format)
      case 'week': {
        const startOfWeek = this.$dayjs(this.week).startOf('week').format(format)
        const endOfWeek = this.$dayjs(this.week).endOf('week').format(format)
        return startOfWeek + formattype + endOfWeek
      }
      case 'month': {
        let startOfMonth, endOfMonth
        if (whichMonth) {
          const year = this.$dayjs().format('YYYY')
          startOfMonth = this.$dayjs(year + '-' + whichMonth).startOf('month').format(format)
          endOfMonth = this.$dayjs(year + '-' + whichMonth).endOf('month').format(format)
        } else {
          startOfMonth = this.$dayjs().startOf('month').format(format)
          endOfMonth = this.$dayjs().endOf('month').format(format)
        }
        return startOfMonth + formattype + endOfMonth
      }
    }
  }

  changeDay () {
    this.dateFormat = this.getDate(this.type, 'MM.DD', '-', this.day)
    this.$emit('loadData', {
      startTime: this.$dayjs(this.day).format('YYYY-MM-DD'),
      endTime: this.$dayjs(this.day).format('YYYY-MM-DD')
    })
  }

  changeWeek () {
    this.dateFormat = this.getDate(this.type, 'MM.DD', '-', this.week)
    this.$emit('loadData', {
      startTime: this.$dayjs(this.week).startOf('week').format('YYYY-MM-DD'),
      endTime: this.$dayjs(this.week).endOf('week').format('YYYY-MM-DD')
    })
  }

  change () {
    this.dateFormat = this.getDate(this.type, 'MM.DD', '-', this.date)
    const time = this.dateFormat.split('-')
    const year = this.$dayjs().format('YYYY') + '.'
    const today = this.$dayjs(year + this.date).format('YYYY-MM-DD')
    this.$emit('loadData', {
      startTime: time.length > 1 ? this.$dayjs(year + time[0]).format('YYYY-MM-DD') : today,
      endTime: time.length > 1 ? this.$dayjs(year + time[1]).format('YYYY-MM-DD') : today
    })
  }

  setActive () {
    this.$emit('setActive')
  }
}
