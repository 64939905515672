
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'
@Component({
  name: 'RightItemView1'
})
export default class RightItemView1 extends Vue {
  @Prop() private projectId!: string
  @Prop() private time!: any
  @Prop() private active!: number

  search = {
    projectId: '',
    startTime: '',
    endTime: ''
  }

  _chart: any = null
  chart: any = {}
  tableList = []

  @Watch('projectId')
  changeProjectId (val: string) {
    this.search.projectId = val
    if (this.search.projectId) {
      this._chart && this._chart.dispose()
    }
    if (this.search.startTime) {
      this.loadData()
    }
  }

  get onlyProject () {
    return this.search.projectId || ''
  }

  @Watch('time', { deep: true })
  changeTime (val: any) {
    this.search.startTime = val.startTime
    this.search.endTime = val.endTime
    this.loadData()
  }

  created () {
    // this.loadData()
  }

  destroyed () {
    this._chart && this._chart.dispose()
  }

  getStatus (status: number) {
    switch (status) {
      case 1:
        return '待执行'
      case 2:
        return '执行中'
      case 4:
        return '已完成'
      case 5:
        return '已逾期'
    }
  }

  loadData () {
    this.$axios.get(this.onlyProject ? this.$apis.apicture.selectQualityInspectionProject : this.$apis.apicture.selectQualityInspectionAll, {
      ...this.search
    }).then(res => {
      if (this.onlyProject) {
        this.tableList = res.taskList || []
        this.$event.$emit('taskLoadCompleted', res)
        this.renderPie(res)
      } else {
        this.$nextTick(() => {
          this.renderPie(res)
          this.renderChart1(res.projectNameList, [res.taskRateList, res.issueRateList, res.projectIdList], ['巡查完成率', '问题处理率'])
        })
      }
    })
  }

  goPosition (item: any) {
    // 1
    this.$event.$emit('taskClick', item)
  }

  renderPie (res: any) {
    const color = ['#628DF6', '#89F3F7', '#FEE186']
    const data = [{
      name: '巡查任务',
      value: res.taskNum || 0
    },
    {
      name: '已完成',
      value: res.taskOffNum || 0
    },
    {
      name: '未完成',
      value: res.taskOn || 0
    }]
    const title = {
      name: '巡查完成率',
      per: res.taskRate
    }
    const color1 = ['#628DF6', '#6AEAA5', '#FF001F']
    const data1 = [{
      name: '问题总数',
      value: res.issueNum || 0
    },
    {
      name: '已处理',
      value: res.issueOffNum || 0
    },
    {
      name: '未处理',
      value: res.issueOn || 0
    }]
    const title1 = {
      name: '问题处理率',
      per: res.issueRate
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.renderChart(data, 'pie0', color, title)
        this.renderChart(data1, 'pie1', color1, title1)
      }, 500)
    })
  }

  renderChart (data: any, chart: any, color: any, title: any) {
    this.chart['_' + chart] = echarts.init(this.$refs[chart] as any)
    const option = {
      tooltip: {
        show: false,
        formatter: '{a} <br/>{b}: {d}%',
        textStyle: {
          fontSize: 12
        }
      },
      title: {
        text: title.per + '{per|%}',
        subtext: title.name,
        left: '22%',
        textAlign: 'center',
        top: 20,
        itemGap: 3,
        textStyle: {
          fontSize: 20,
          color: '#9013FE',
          fontFamily: 'DINCondensed, DINCondensed',
          rich: {
            per: {
              fontSize: 12,
              fontFamily: 'DINCondensed, DINCondensed'
            }
          }
        },
        subtextStyle: {
          fontSize: 8,
          color: '#000000',
          fontFamily: 'PingFangSC, PingFang SC'
        }
      },
      legend: {
        orient: 'vertical',
        top: 'center',
        left: '49%',
        icon: 'circle',
        itemWidth: 6,
        formatter: (name: string) => {
          const index = data.findIndex((item: any) => item.name === name)
          const arr = '{a|' + name + '}{b|' + data[index].value + '}'
          return arr
        },
        textStyle: {
          fontSize: 12,
          color: '#4A4A4A',
          align: 'center',
          rich: {
            a: {
              color: '#4A4A4A',
              padding: [0, 0, 0, 2]
            },
            b: {
              color: '#4A4A4A',
              padding: [15, 0, 15, 3]
            }
          }
        }
      },
      color: color,
      series: [
        {
          // avoidLabelOverlap: false,
          hoverOffset: 0,
          type: 'pie',
          radius: ['90%', '100%'],
          center: ['25%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          zlevel: 4,
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 0,
              color: (value: any) => {
                return color[value.dataIndex]
              }
              // shadowColor: 'rgba(0,0,0,0.4)',
              // shadowBlur: 10
            }
          },
          data: data,
          labelLine: {
            show: false
          }
        },
        {
          labelLine: {
            show: false
          },
          itemStyle: {
            opacity: 0.2,
            borderColor: '#ffffff',
            borderWidth: 2
          },
          type: 'pie',
          hoverAnimation: false,
          radius: ['70%', '90%'],
          center: ['25%', '50%'],
          label: {
            normal: {
              show: false
            }
          },
          data: data,
          zlevel: -1
        }
      ]
    }
    this.chart['_' + chart].clear()
    this.chart['_' + chart].setOption(option)
  }

  renderChart1 (x: any, y: any, legend: any) {
    this._chart = echarts.init(this.$refs.chart1 as any)
    const option = {
      grid: {
        top: 27,
        bottom: 30,
        left: 30,
        right: 0
      },
      color: ['#2db7f5', '#ff6600'],
      legend: {
        top: 0,
        width: '100%',
        right: 'center',
        textStyle: {
          fontSize: 12,
          color: '#909090'
        },
        // data: [{
        //   name: '巡查完成率',
        //   textStyle: {
        //     backgroundColor: '#0361FE'
        //   }
        // },
        // {
        //   name: '问题处理率',
        //   textStyle: {
        //     backgroundColor: '#FEE186'
        //   }
        // }],
        itemGap: 16,
        itemWidth: 14,
        itemHeight: 5
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        className: 'echarts-tooltip-dark',
        formatter (res: any) {
          const colors = ['#fff']
          let content = `${res[0].name}<br>`
          for (let i = 0; i < res.length; i++) {
            content += `${res[i].marker.replace('background-color:[object Object]', 'background-color:' + colors[i / 2])}${res[i].seriesName}  <span style="color:#fff};">${res[i].value}%</span><br>`
          }
          return content
        }
      },
      xAxis: {
        show: true,
        data: x,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false // 隐藏X轴刻度
        },
        axisLabel: {
          show: true,
          fontSize: 10,
          fontWeight: 400,
          textStyle: {
            color: '#4A4A4A' // X轴文字颜色
          },
          formatter: (item: any) => {
            return item.length > 9 ? item.substring(0, 4) + '...' + item.substring(item.length - 4, item.length) : item
          }
        }
      },
      yAxis: [
        {
          name: '(%)',
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            margin: 4,
            fontSize: 12,
            textStyle: {
              color: '#4A4A4A' // X轴文字颜色
            }
          }
        }
      ],
      dataZoom: [{
        type: 'slider',
        show: x.length > 2,
        bottom: 0,
        height: 5,
        start: 0,
        end: 50, // 初始化滚动条
        borderColor: 'transparent',
        backgroundColor: '#D9D9D9',
        fillerColor: '#999999',
        handleStyle: {
          opacity: 0
        },
        showDetail: false,
        showDataShadow: false,
        brushSelect: false
      }],
      series: [
        {
          name: legend[0],
          data: y[0],
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#0361FE'
            },
            {
              offset: 1,
              color: '#ffffff'
            }
            ])
          },
          barWidth: 10,
          z: 0,
          zlevel: 1
        },
        {
          // 分隔
          name: legend[1],
          data: y[1],
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#3BE011'
            },
            {
              offset: 1,
              color: '#ffffff'
            }
            ])
          },
          barWidth: 10,
          z: 0,
          zlevel: 2
        }
      ]
    }
    this._chart.clear()
    this._chart.setOption(option)
    this._chart.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY]
      if (this._chart.containPixel('grid', pointInPixel)) {
        const xIndex = this._chart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)[0]
        // const index = this.projectList.findIndex((item: any) => item.projectName === x[xIndex])
        this.$event.$emit('projectClick', y[2][xIndex])
      }
    })
  }
}

